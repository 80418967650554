




























































































































































































import { bus } from "@/main";
import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import ClickOutside from "vue-click-outside";

@Component({
    components: {
        LayoutUser: () => import("@/layouts/presets/user.vue"),
        Paywall: () => import("@/components/general/paywall.vue"),
        PanicOverlay: () => import("@/components/general/panic-overlay.vue"),
    },
    directives: {
        ClickOutside,
    },
})
export default class PageUser extends Vue {
    @Getter("journal/viewingWeek") viewingWeek!: boolean;
    @Getter("journal/viewingDay") viewingDay!: Date;
    @Getter("journal/plans") plans!: Array<Plan>;
    @Getter("auth/me") account!: Account;
    @Getter("auth/showPaywall") showPaywall!: boolean;
    @Getter("journal/unreadReactions") unreadReactions!: Array<Reaction>;
    @Getter("panic/showOverlay") showPanicOverlay!: boolean;
    @Getter("auth/isDietician") isDietician!: boolean;
    @Getter("auth/isAdmin") isAdmin!: boolean;

    @Action("journal/setViewingDay") setViewingDay!: SetViewingDay;

    expandAddButton: boolean = false;
    planIndex: number | null = null;
    planEdit: boolean = false;

    mounted() {
        bus.$on("open-plan-overlay", (index: number, edit = false) => {
            this.planIndex = index;
            this.planEdit = edit;
            this.expandAddButton = true;
        });
    }

    handleJournalClicked() {
        this.setViewingDay(new Date());
    }

    closeAddButton() {
        this.planIndex = null;
        this.expandAddButton = false;
    }

    get theme(): string {
        if (this.$route.name === "dashboard" || this.$route.name === "product-show") {
            return "dark";
        }
        return "light";
    }

    get viewingFutureDate() {
        const now = new Date();
        return this.viewingDay > now;
    }

    get showAddButton() {
        return this.$route.name === "discover" || (this.$route.name === "journal" && !this.viewingWeek && (!this.viewingFutureDate || this.expandAddButton || (this.viewingFutureDate && this.account.eating_moments !== this.plans.length)));
    }
}
